import React from 'react';
import gql from 'graphql-tag';
import client from '../../../utils/apolloClient';

// {
//   user_id: "c23dfc73-6076-445d-8026-c9219514582d"
//   filter: {
//     date_filter: {
//       start_date: "2023-07-24T07:22:46.683Z"
//       end_date: "2024-08-24T07:22:46.683Z"
//     }
//   }
// }

const searchUserOrdersDashboardQuery = gql`
  query searchUserOrdersDashboardQuery($input: SearchInput) {
    search_user_orders_dashboard(input: $input) {
      allUser
      activeUser
      newUser
      allFoodOrdersUser
      activeFoodOrderUser
      newFoodOrdersUser
      ordersToDate
      totalOrdersToDateOrderAmount
      orders
      totalOrderAmount
      pickupOrderToDate
      totalPickupOrderToDateOrderAmount
      pickupOrder
      totalPickupOrderAmount
      dineinOrderToDate
      totalDineinOrderToDateOrderAmount
      dineinOrder
      totalDineinOrderAmount
      deliveryOrderToDate
      totalDeliveryOrderToDateOrderAmount
      deliveryOrder
      totalDeliveryOrderAmount
      tableBooking
      totalTableBookingAmount
    }
  }
`;

export const searchUserOrdersDashboard = async ({
  adminUserId,
  startDate = null,
  endDate = null,
  place_filter,
}) => {
  const input = { user_id: adminUserId };

  input.filter = {};
  input.filter.place_filter = place_filter;

  if (startDate || endDate) {
    input.filter.date_filter = {};
  }

  if (startDate) {
    input.filter.date_filter.start_date = startDate;
  }

  if (endDate) {
    input.filter.date_filter.end_date = endDate;
  }

  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchUserOrdersDashboardQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (data && data?.data?.search_user_orders_dashboard) {
      return data.data.search_user_orders_dashboard;
    }

    return null;
  } catch {
    return null;
  }
};

export const useUserOrdersDashboardData = ({
  adminUserId,
  startDate = null,
  endDate = null,
  place_filter,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [userOrdersDashboardData, setUserOrdersDashboardData] = React.useState(
    null,
  );

  React.useEffect(() => {
    const fetchFunc = async () => {
      setLoading(true);
      setUserOrdersDashboardData(null);
      if (adminUserId) {
        const res = await searchUserOrdersDashboard({
          adminUserId,
          startDate,
          endDate,
          place_filter,
        });

        if (res) {
          setUserOrdersDashboardData(res);
        } else {
          // pass
        }
      } else {
        // pass
      }

      setLoading(false);
    };
    fetchFunc();
  }, [
    adminUserId,
    startDate,
    endDate,
    place_filter.is_test_place,
    place_filter.is_cash_exclude,
  ]);

  return [loading, userOrdersDashboardData];
};

export const downloadCSVFile = (csvData, fileName) => {
  const CSVFile = new Blob([csvData], { type: 'text/csv' });
  const temp_link = document.createElement('a');
  temp_link.download = fileName;
  const url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = 'none';
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
};

export default {};
