import React, { useState } from 'react';
import { Column, Tab } from 'rbx';
import { useStoreState } from 'easy-peasy';
import uuidv4 from 'uuid/v4';
// import swal from 'sweetalert';

import { Tabs, CheckBox } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';

import OrderDetailsTable from './OrderDetailsTable';
import OrderSummary from './OrderSummary';
import {
  fetchUserOrderSummary,
  fetchUserOrdersDetails,
  transformUserOrderSummary,
  transformUserOrderDetails,
  downloadCSVFile,
} from './helpers';

const UserOrdersHistory = () => {
  const { userId } = useStoreState(state => state.auth);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('TAB1');
  const [userOrderSummaryData, setUserOrderSummaryData] = useState([]);
  const [userOrderDetailsData, setUserOrdersDetailsData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userIdForOrderDetails, setUserIdForOrderDetails] = useState();

  const [placename, setPlacename] = useState('');
  const [username, setUsername] = useState('');
  const [useremail, setUseremail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState();
  const [orderRef, setOrderRef] = useState('');
  const [status, setStatus] = useState();
  const [service, setService] = useState();
  const [selectionKey, setSelectionKey] = useState(uuidv4());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateTimeActive, setDateTimeActive] = React.useState(false);
  const [phone, setPhone] = useState();
  const [counts, setCounts] = useState({ tab1: 0, tab2: 0 });

  const [excludeTestPlace, setExcludeTestPlace] = React.useState(true);
  const [isCashExclude, setIsCashExclude] = React.useState(true);

  const isTestPlace = !excludeTestPlace;

  const selectUserId = id => {
    setUserIdForOrderDetails(id);
  };

  const canLoadMoreData = currentPage + 1 < totalPages;

  console.log('userOrderSummaryData...', userOrderSummaryData);

  const loadMoreDataFunc = () => {
    setCurrentPage(currentPage + 1);
  };

  const onDownloadCsv = () => {
    const data = Array.from(document.getElementsByTagName('tr')).map(row =>
      Array.from(row.querySelectorAll('td,th'))
        .map(tdth => tdth.innerText.replace(/,/g, ' '))
        .join(','),
    );
    if (activeTab === 'TAB1') {
      const csvData = [`Order Summary`, ...data].join('\n');
      downloadCSVFile(csvData, `order_summary.csv`);
    }
    if (activeTab === 'TAB2') {
      const csvData = [`Order Details`, ...data].join('\n');
      downloadCSVFile(csvData, `order_details.csv`);
    }
  };

  React.useEffect(() => {
    let ignore = false;
    const fetchTab1Data = async () => {
      setLoading(true);
      const response = await fetchUserOrderSummary({
        userId,
        from: currentPage * 50,
        username,
        useremail,
        phone,
        isTestPlace,
        isCashExclude,
        placename,
        startDate,
        endDate,
        service: service?.value,
      });
      if (!ignore && response && currentPage === 0) {
        setUserOrderSummaryData(transformUserOrderSummary(response.data));
        setTotalPages(response.totalPages);
        setCounts(prev => ({ ...prev, tab1: response.totalSize }));
      } else if (!ignore && response) {
        setUserOrderSummaryData([
          ...userOrderSummaryData,
          ...transformUserOrderSummary(response.data),
        ]);
      }
      setLoading(false);
    };

    const fetchTab2Data = async () => {
      setLoading(true);
      const response = await fetchUserOrdersDetails({
        userId: userIdForOrderDetails,
        adminUserId: userId,
        from: currentPage * 50,
        placename,
        orderRef,
        paymentMethod: paymentMethod?.value,
        status: status?.value,
        service: service?.value,
        startDate,
        endDate,
        isTestPlace,
        isCashExclude,
      });
      if (!ignore && response && currentPage === 0) {
        setUserOrdersDetailsData(transformUserOrderDetails(response.data));
        setTotalPages(response.totalPages);
        setCounts(prev => ({ ...prev, tab2: response.totalSize }));
      } else if (!ignore && response) {
        setUserOrdersDetailsData([
          ...userOrderDetailsData,
          ...transformUserOrderDetails(response.data),
        ]);
      }
      setLoading(false);
    };

    if (activeTab === 'TAB1') {
      fetchTab1Data();
    }

    if (activeTab === 'TAB2') {
      fetchTab2Data();
    }

    return () => {
      ignore = true;
    };
  }, [
    userId,
    activeTab,
    currentPage,
    userIdForOrderDetails,
    username,
    useremail,
    placename,
    orderRef,
    paymentMethod?.value,
    status?.value,
    service?.value,
    startDate,
    endDate,
    phone,
    isTestPlace,
    isCashExclude,
  ]);

  React.useEffect(() => {
    setPlacename('');
    setUsername('');
    setUseremail('');
    setPaymentMethod();
    setOrderRef('');
    setStatus();
    setService();
    setSelectionKey(uuidv4());
    setDateTimeActive(false);
  }, [activeTab]);

  React.useEffect(() => {
    setCurrentPage(0);
    setTotalPages(0);
  }, [
    placename,
    username,
    useremail,
    paymentMethod,
    orderRef,
    status,
    service,
    startDate,
    endDate,
  ]);

  return (
    <Layout>
      <Header />

      <Column.Group
        style={{ padding: '1rem 1rem 0 1rem' }}
        vcentered
        multiline
        gapSize={8}
      >
        <Column size={3}>
          <CheckBox
            label="Exclude Test Restaurants"
            value={excludeTestPlace}
            onChange={value => {
              setExcludeTestPlace(value);
            }}
          />
        </Column>
        <Column size={3}>
          <CheckBox
            label="Exclude Cash Orders"
            name="event_business"
            value={isCashExclude}
            onChange={value => {
              setIsCashExclude(value);
            }}
          />
        </Column>
      </Column.Group>

      <Search
        phone={phone}
        setPhone={setPhone}
        selectionKey={selectionKey}
        refetchData={() => {}}
        endDate={endDate}
        setEndDate={setEndDate}
        startDate={startDate}
        setStartDate={setStartDate}
        placename={placename}
        username={username}
        useremail={useremail}
        paymentMethod={paymentMethod}
        orderRef={orderRef}
        status={status}
        service={service}
        disabledForActiveTab1={activeTab === 'TAB1'}
        disabledForActiveTab2={activeTab === 'TAB2'}
        onPlacenameChange={v => setPlacename(v)}
        onUsernameChange={v => setUsername(v)}
        onUseremailChange={v => setUseremail(v)}
        onPaymentMethodChange={v => setPaymentMethod(v)}
        onOrderRefChange={v => setOrderRef(v)}
        onStatusChange={v => setStatus(v)}
        onServiceChange={v => setService(v)}
        setDateTimeActive={setDateTimeActive}
        dateTimeActive={dateTimeActive}
      />
      <div style={{ position: 'absolute', right: 0 }}>
        <button
          className="button is-primary is-outlined"
          style={{ margin: '5px' }}
          onClick={onDownloadCsv}
        >
          Download CSV
        </button>
      </div>
      <Tabs className="tabs is-medium is-fullwidth">
        <Tab
          onClick={() => {
            setActiveTab('TAB1');
            setTotalPages(0);
            setCurrentPage(0);

            setUserOrderSummaryData([]);
            setUserOrdersDetailsData([]);
          }}
          active={activeTab === 'TAB1'}
          style={{ cursor: activeTab === 'TAB1' ? 'text' : 'pointer' }}
        >
          Order Summary {counts.tab1 === 0 ? '' : `(${counts.tab1})`}
        </Tab>
        <Tab
          onClick={() => {
            if (activeTab === 'TAB1') {
              setActiveTab('TAB2');
              setTotalPages(0);
              setCurrentPage(0);
              setUserOrderSummaryData([]);
              setUserOrdersDetailsData([]);
            }
          }}
          active={activeTab === 'TAB2'}
          style={{ cursor: activeTab === 'TAB2' ? 'text' : 'pointer' }}
        >
          Order Details {counts.tab2 === 0 ? '' : `(${counts.tab2})`}
        </Tab>
      </Tabs>
      {activeTab === 'TAB1' && (
        <OrderSummary
          loading={loading}
          userOrderSummaryData={userOrderSummaryData}
          canLoadMoreData={canLoadMoreData}
          loadMoreData={loadMoreDataFunc}
          selectUserId={selectUserId}
          changeTab={() => {
            setActiveTab('TAB2');
            setTotalPages(0);
            setCurrentPage(0);
            setUserOrderSummaryData([]);
            setUserOrdersDetailsData([]);
          }}
        />
      )}
      {activeTab === 'TAB2' && (
        <OrderDetailsTable
          loading={loading}
          userOrderDetailsData={userOrderDetailsData}
          canLoadMoreData={canLoadMoreData}
          loadMoreData={loadMoreDataFunc}
        />
      )}
    </Layout>
  );
};

export default UserOrdersHistory;
