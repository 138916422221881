/* eslint-disable no-else-return */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Tab, Column } from 'rbx';
import moment from 'moment';
import qs from 'querystringify';
import uuidv4 from 'uuid/v4';
import gql from 'graphql-tag';
import { capitalize } from 'lodash';

import { useStoreState } from 'easy-peasy';
import Layout from '../../../components/global/Layout';
import { Box, Tabs, CheckBox } from '../../../components/elements';
import Header from './Header';

import OrderList from './OrderList';
import { getOrders, getOrderCount } from './helpers';
import Search from './Search';
import client from '../../../utils/apolloClient';

const Container = styled.div``;

const fetchNameOfUser = gql`
  query fetchUser($input: NavigateUserInput) {
    fetch_user(input: $input) {
      first_name
      last_name
    }
  }
`;

const userDisplayNameFromBackend = async ({ userId }) => {
  const data = await client.clientPrivate.query({
    query: fetchNameOfUser,
    variables: { input: { user_id: userId } },
  });
  if (data && data.data && data.data.fetch_user) {
    return {
      userId,
      userName: data.data.fetch_user.last_name
        ? `${capitalize(data.data.fetch_user.first_name)} ${capitalize(
            data.data.fetch_user.last_name,
          )}`
        : capitalize(data.data.fetch_user.first_name),
    };
  }
  return { userId, userName: null };
};

const transformFromFilter = fromFilter => {
  const originalTimestamp = moment.utc(fromFilter);
  const convertedTimestamp = originalTimestamp.set({
    hour: 11,
    minute: 59,
    second: 0,
    millisecond: 0,
  });
  return convertedTimestamp.toISOString();
};

const Orders = props => {
  const { userId } = useStoreState(state => state.auth);
  const routeState = qs.parse(props.location.search);
  const { placeName, city, from, to, tab } = routeState;

  const placeNameFilter = placeName || '';
  const cityFilter = city || '';
  // const fromFilter = fromFilter ? transformFromFilter(fromFilter) : null;
  const fromFilter = from || null;
  const toFilter = to || null;
  const activeTab = tab || 'new';

  const activeTabRef = useRef({});
  activeTabRef.current.activeTab = activeTab;

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderCount, setOrderCount] = useState({
    receivedOrder: 0,
    confirmOrder: 0,
    readyOrder: 0,
    completeOrder: 0,
    cancelOrder: 0,
    draftOrder: 0,
    scheduleOrder: 0,
  });
  const [draftOrderCreatedBy, setDraftOrderCreatedBy] = useState({});

  const [randomStateToTriggerFetch, setRandomStateToTriggerFetch] = useState(
    uuidv4(),
  );

  const triggerReRenderOfDurationColumn = useRef(uuidv4());

  const [sort, setSort] = useState('CREATED_AT_DESC');

  const [searchByOrderNumber, setSearchByOrderNumber] = useState(false);

  const [orderNumber, setOrderNumber] = useState('');

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const canLoadMoreData = currentPage < totalPages;

  const [excludeTestPlace, setExcludeTestPlace] = React.useState(true);
  const [isCashExclude, setIsCashExclude] = React.useState(true);

  console.log({ totalPages, currentPage });

  const fetchOrderCount = async () => {
    const setDateHours = dateObject => {
      dateObject.setHours(0, 0, 0, 0);
      return dateObject;
    };

    const startDateISOString = dateObject =>
      setDateHours(dateObject).toISOString();

    const place_filter = {};
    Object.assign(
      place_filter,
      placeNameFilter &&
        placeNameFilter.length !== 0 && {
          place_name: placeNameFilter,
        },
      cityFilter &&
        cityFilter.length !== 0 && {
          city: cityFilter,
        },
      { is_cash_exclude: isCashExclude, is_test_place: !excludeTestPlace },
    );
    const filter = {
      place_filter,
    };
    // if (fromFilter || toFilter) {
    //   filter.menu_order_filter = {};
    //   filter.menu_order_filter.date_range = {
    //     start_date: fromFilter || startDateISOString(new Date()),
    //     end_date: toFilter || moment().toISOString(),
    //   };
    // }
    try {
      const count1 = await getOrderCount({
        user_id: userId,
        filter: {
          ...filter,
        },
      });

      const count2 = await getOrderCount({
        user_id: userId,
        filter: {
          menu_order_filter: {
            date_range: {
              start_date: fromFilter
                ? transformFromFilter(fromFilter)
                : startDateISOString(new Date()),
              end_date: toFilter || moment().toISOString(),
            },
          },
          place_filter,
        },
      });

      setOrderCount(prev => ({
        ...prev,
        receivedOrder: count1.received,
        confirmOrder: count1.confirmed,
        readyOrder: count1.ready,
        completeOrder: count2.complete,
        cancelOrder: count2.cancelled,
      }));
    } catch {
      // pass
    }
  };

  useEffect(() => {
    fetchOrderCount();
  }, [
    toFilter,
    fromFilter,
    sort,
    placeNameFilter,
    cityFilter,
    activeTab,
    randomStateToTriggerFetch,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, placeNameFilter, cityFilter, fromFilter, toFilter]);

  const fetchData = async ({ status }) => {
    const setDateHours = dateObject => {
      dateObject.setHours(0, 0, 0, 0);
      return dateObject;
    };

    const startDateISOString = dateObject =>
      setDateHours(dateObject).toISOString();

    const scheduleDate = () => {
      const currentDate = new Date();
      const tomorrowDate = new Date(currentDate);
      tomorrowDate.setDate(currentDate.getDate() + 1);
      return tomorrowDate;
    };

    const scheduleEndDate = () => {
      const currentDate = new Date();
      const tomorrowDate = new Date(currentDate);
      tomorrowDate.setDate(currentDate.getDate() + 30);
      return tomorrowDate;
    };

    setOrders([]);
    setIsLoading(true);
    const menu_order_filter = {
      status,
    };
    // const menu_order_filter_for_count = {
    //   date_range: {
    //     start_date: fromFilter || startDateISOString(new Date()),
    //     end_date: toFilter || moment().toISOString(),
    //   },
    // };

    // Object.assign(
    //   menu_order_filter,
    //   status[0] !== 'SCHEDULED' && {
    //     date_range: {
    //       start_date: fromFilter || startDateISOString(new Date()),
    //       end_date: toFilter || moment().toISOString(),
    //     },
    //   },
    //   status[0] === 'SCHEDULED' && {
    //     date_range: {
    //       start_date: startDateISOString(scheduleDate()),
    //       end_date: startDateISOString(scheduleEndDate()),
    //     },
    //     is_scheduled: true,
    //     status: ['CONFIRMED'],
    //   },
    // );

    if (status[0] === 'SCHEDULED') {
      Object.assign(menu_order_filter, {
        date_range: {
          start_date: startDateISOString(scheduleDate()),
          end_date: startDateISOString(scheduleEndDate()),
        },
        is_scheduled: true,
        status: ['CONFIRMED'],
      });
    } else if (
      status[0] === 'CONFIRMED' ||
      status[0] === 'RECEIVED' ||
      status[0] === 'READY'
    ) {
      // pass
    } else {
      Object.assign(menu_order_filter, {
        date_range: {
          start_date: fromFilter
            ? transformFromFilter(fromFilter)
            : startDateISOString(new Date()),
          end_date: toFilter || moment().toISOString(),
        },
      });
    }

    const place_filter = {};
    Object.assign(
      place_filter,
      placeNameFilter &&
        placeNameFilter.length !== 0 && {
          place_name: placeNameFilter,
        },
      cityFilter &&
        cityFilter.length !== 0 && {
          city: cityFilter,
        },
      { is_cash_exclude: isCashExclude, is_test_place: !excludeTestPlace },
    );

    const data = await getOrders({
      sort,
      from: 0,
      size: 50,
      user_id: userId,
      filter: {
        menu_order_filter,
        place_filter,
      },
    });
    if (data.total_pages) {
      setTotalPages(data.total_pages);
    }

    setOrders(data.menu_order_listing || []);

    if (status[0] === 'DRAFT') {
      setOrderCount(prev => ({
        ...prev,
        draftOrder: (data.menu_order_listing ?? []).length,
      }));
    }

    if (status[0] === 'SCHEDULED') {
      setOrderCount(prev => ({
        ...prev,
        scheduleOrder: (data.menu_order_listing ?? []).length,
      }));
    }

    setIsLoading(false);
  };

  activeTabRef.current.fetchData = fetchData;

  activeTabRef.current.fetchOrderCount = fetchOrderCount;

  const refetchData = () => {
    setRandomStateToTriggerFetch(uuidv4);
  };

  const refetchOrderData = async ({ status }) => {
    setLoadingMoreData(true);
    const setDateHours = dateObject => {
      dateObject.setHours(0, 0, 0, 0);
      return dateObject;
    };

    const startDateISOString = dateObject =>
      setDateHours(dateObject).toISOString();
    const menu_order_filter = {
      status: [status],
    };
    Object.assign(menu_order_filter, {
      date_range: {
        start_date: fromFilter
          ? transformFromFilter(fromFilter)
          : startDateISOString(new Date()),
        end_date: toFilter || moment().toISOString(),
      },
    });
    const place_filter = {};
    Object.assign(
      place_filter,
      placeNameFilter &&
        placeNameFilter.length !== 0 && {
          place_name: placeNameFilter,
        },
      cityFilter &&
        cityFilter.length !== 0 && {
          city: cityFilter,
        },
      { is_cash_exclude: isCashExclude, is_test_place: !excludeTestPlace },
    );
    const data = await getOrders({
      sort,
      from: currentPage * 50,
      size: 50,
      user_id: userId,
      filter: {
        menu_order_filter,
        place_filter,
      },
    });

    if (data.menu_order_listing) {
      if (status === 'DRAFT') {
        setOrderCount(prev => ({
          ...prev,
          draftOrder: [...orders, ...data.menu_order_listing].length,
        }));
      }
      setOrders(prev => [...prev, ...data.menu_order_listing]);

      setCurrentPage(currentPage + 1);
    }
    setLoadingMoreData(false);
  };

  useEffect(() => {
    if (searchByOrderNumber) {
      return;
    }
    if (activeTab === 'new') {
      setOrders([]);
      fetchData({ status: ['RECEIVED'] });
    }
    if (activeTab === 'confirmed') {
      setOrders([]);
      fetchData({ status: ['CONFIRMED'] });
    }
    if (activeTab === 'ready') {
      setOrders([]);
      fetchData({ status: ['READY'] });
    }
    if (activeTab === 'completed') {
      setOrders([]);
      fetchData({ status: ['COMPLETE'] });
    }
    if (activeTab === 'cancelled') {
      setOrders([]);
      fetchData({ status: ['CANCELLED'] });
    }
    if (activeTab === 'draft') {
      setOrders([]);
      fetchData({ status: ['DRAFT'] });
    }
    if (activeTab === 'scheduled') {
      setOrders([]);
      fetchData({ status: ['SCHEDULED'] });
    }
  }, [
    toFilter,
    fromFilter,
    sort,
    placeNameFilter,
    cityFilter,
    activeTab,
    randomStateToTriggerFetch,
    searchByOrderNumber,
  ]);

  useEffect(() => {
    const fetchDataForSearchByOrderNumber = async () => {
      const menu_order_filter = {};
      setIsLoading(true);
      Object.assign(menu_order_filter, {
        order_reference: orderNumber,
      });
      const place_filter = {};
      Object.assign(
        place_filter,
        placeNameFilter &&
          placeNameFilter.length !== 0 && {
            place_name: placeNameFilter,
          },
        cityFilter &&
          cityFilter.length !== 0 && {
            city: cityFilter,
          },
        { is_cash_exclude: isCashExclude, is_test_place: !excludeTestPlace },
      );
      const data = await getOrders({
        sort,
        from: 0,
        size: 50,
        user_id: userId,
        filter: {
          menu_order_filter,
          place_filter,
        },
      });
      setOrders(data?.menu_order_listing ?? []);
      setIsLoading(false);
    };
    if (orderNumber) {
      setOrders([]);
      fetchDataForSearchByOrderNumber();
    }
  }, [sort, placeNameFilter, cityFilter, orderNumber]);

  useEffect(() => {
    const timer = setInterval(() => {
      triggerReRenderOfDurationColumn.current = uuidv4();
    }, [1000]);
    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    const tempFetchFunc = async () => {
      if (
        activeTab === 'draft' &&
        Array.isArray(orders) &&
        orders.length !== 0
      ) {
        console.log('Number of Draft Orders = ', orders.length);
        const userIdOrderIdMap = {};
        const orderIdUserNameMap = {};
        orders.forEach(order => {
          if (order?.audit?.created_by) {
            userIdOrderIdMap[order?.audit?.created_by] = order.menu_order_id;
          } else {
            orderIdUserNameMap[order.menu_order_id] = null;
          }
        });
        console.log('sending request...');
        const userNames = await Promise.all(
          Object.keys(userIdOrderIdMap).map(userIdFromOrder =>
            userDisplayNameFromBackend({ userId: userIdFromOrder }),
          ),
        );
        userNames.forEach(name => {
          orderIdUserNameMap[userIdOrderIdMap[name.userId]] = name.userName;
        });
        console.log('draft order created by = ', orderIdUserNameMap);
        setDraftOrderCreatedBy(orderIdUserNameMap);
      }
    };
    tempFetchFunc();
  }, [activeTab, orders]);

  const handleTab = value => {
    props.history.push(
      `/orders-dashboard${qs.stringify(
        {
          ...routeState,
          page: 1,
          tab: value,
        },
        true,
      )}`,
    );
    // props.history.push(`/orders-dashboard?tab=${value}`);
  };

  useEffect(() => {
    console.log('mount the timer that updates the new tab order details...');
    if (searchByOrderNumber) {
      // pass
    } else {
      const timer = setInterval(() => {
        if (activeTabRef.current.activeTab === 'new') {
          console.log('invoking fetchData...');
          activeTabRef.current.fetchData({ status: ['RECEIVED'] });
          activeTabRef.current.fetchOrderCount();
        } else {
          console.log('refetching count data...');
          activeTabRef.current.fetchOrderCount();
        }
      }, 30000);
      return () => {
        console.log(
          'clears the timer that updates the new tab order details...',
        );
        clearInterval(timer);
      };
    }
    return () => {};
  }, [searchByOrderNumber]);

  return (
    <Layout>
      <Header />
      <Column.Group
        style={{ padding: '1rem 1rem 0 1rem' }}
        vcentered
        multiline
        gapSize={8}
      >
        <Column size={3}>
          <CheckBox
            label="Exclude Test Restaurants"
            value={excludeTestPlace}
            onChange={value => {
              setExcludeTestPlace(value);
            }}
          />
        </Column>
        <Column size={3}>
          <CheckBox
            label="Exclude Cash Orders"
            name="event_business"
            value={isCashExclude}
            onChange={value => {
              setIsCashExclude(value);
            }}
          />
        </Column>
      </Column.Group>
      <Search
        refetchData={refetchData}
        placeName={placeNameFilter}
        city={cityFilter}
        startDate={fromFilter}
        endDate={toFilter}
        routeState={routeState}
        searchByOrderNumber={searchByOrderNumber}
        setSearchByOrderNumber={setSearchByOrderNumber}
        orderNumber={orderNumber}
        setOrderNumber={setOrderNumber}
        setOrders={setOrders}
      />

      <Box>
        {!searchByOrderNumber && (
          <Tabs className="tabs is-medium is-fullwidth">
            <Tab active={activeTab === 'new'} onClick={() => handleTab('new')}>
              New ({orderCount.receivedOrder})
            </Tab>

            <Tab
              active={activeTab === 'confirmed'}
              onClick={() => handleTab('confirmed')}
            >
              CONFIRMED ({orderCount.confirmOrder})
            </Tab>

            <Tab
              active={activeTab === 'ready'}
              onClick={() => handleTab('ready')}
            >
              READY ({orderCount.readyOrder})
            </Tab>

            <Tab
              active={activeTab === 'completed'}
              onClick={() => handleTab('completed')}
            >
              COMPLETED ({orderCount.completeOrder})
            </Tab>

            <Tab
              active={activeTab === 'scheduled'}
              onClick={() => handleTab('scheduled')}
            >
              SCHEDULED ({orderCount.scheduleOrder})
            </Tab>

            <Tab
              active={activeTab === 'cancelled'}
              onClick={() => handleTab('cancelled')}
            >
              CANCELLED ({orderCount.cancelOrder})
            </Tab>
            <Tab
              active={activeTab === 'draft'}
              onClick={() => handleTab('draft')}
            >
              DRAFTS ({orderCount.draftOrder})
            </Tab>
          </Tabs>
        )}
        <Container>
          <OrderList
            {...props}
            triggerReRenderOfDurationColumn={
              triggerReRenderOfDurationColumn.current
            }
            orders={orders}
            isLoading={isLoading}
            isActiveTab={activeTab}
            sort={sort}
            setSort={setSort}
            refetchData={refetchData}
            draftOrderCreatedBy={draftOrderCreatedBy}
            canLoadMoreData={canLoadMoreData}
            loadingMoreData={loadingMoreData}
            refetchOrderData={refetchOrderData}
            refetchDraftOrderData={refetchOrderData}
            loadMoreDraftData={canLoadMoreData}
          />
        </Container>
      </Box>
    </Layout>
  );
};

export default Orders;
