import gql from 'graphql-tag';
import format from 'date-fns/format';
import client from '../../../utils/apolloClient';

const searchUserOrderSummaryQuery = gql`
  query searchUserOrderSummaryQuery($input: SearchInput) {
    search_user_order_summary(input: $input) {
      total_pages
      total_size
      user_order_summary_listing {
        user_id
        display_name
        email
        total_orders
        total_sales
        first_order
        last_order
        last_order_location
        pickup_orders
        pickup_sales
        dinein_orders
        dinein_sales
        delivery_orders
        delivery_sales
        total_places_involved_count
        average_sales
        first_name
        last_name
      }
    }
  }
`;

export const fetchUserOrderSummary = async ({
  userId,
  from = 0,
  size = 50,
  username,
  useremail,
  phone,
  isTestPlace,
  isCashExclude,
  placename,
  startDate,
  endDate,
  service,
}) => {
  try {
    const filterObj = {};
    filterObj.place_filter = {};
    filterObj.place_filter.is_cash_exclude = isCashExclude;
    filterObj.place_filter.is_test_place = isTestPlace;

    if (placename) {
      filterObj.place_filter.place_name = placename;
    }

    if (service && service !== 'All' && filterObj.menu_order_filter) {
      filterObj.menu_order_filter.service_type = service;
    } else if (service && service !== 'All') {
      filterObj.menu_order_filter = {};
      filterObj.menu_order_filter.service_type = service;
    }

    if (phone && filterObj.menu_order_filter) {
      filterObj.menu_order_filter.contact_phone = phone;
    } else if (phone) {
      filterObj.menu_order_filter = {};
      filterObj.menu_order_filter.contact_phone = phone;
    }

    Object.assign(
      filterObj,
      username && useremail
        ? { user_filter: { email: useremail, display_name: username } }
        : {},
      username && !useremail ? { user_filter: { display_name: username } } : {},
      !username && useremail ? { user_filter: { email: useremail } } : {},
    );

    if (new Date(startDate)?.getTime() === new Date(endDate)?.getTime()) {
      // pass
    } else if (startDate && endDate) {
      if (filterObj.menu_order_filter) {
        filterObj.menu_order_filter.date_range = {
          start_date: startDate,
          end_date: endDate,
        };
      } else {
        filterObj.menu_order_filter = {};
        filterObj.menu_order_filter.date_range = {
          start_date: startDate,
          end_date: endDate,
        };
      }
    }
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchUserOrderSummaryQuery,
      variables: {
        input: {
          user_id: userId,
          from,
          size,
          filter: filterObj,
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data &&
      data.data.search_user_order_summary &&
      Array.isArray(
        data.data.search_user_order_summary.user_order_summary_listing,
      )
    ) {
      console.log(
        'search_transactions_summary',
        data.data.search_user_order_summary,
      );

      return {
        data: data.data.search_user_order_summary.user_order_summary_listing,
        totalPages: data.data.search_user_order_summary.total_pages,
        totalSize: data.data.search_user_order_summary.total_size,
      };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const transformUserOrderSummary = summaries =>
  (summaries ?? []).map(summary => ({
    id: summary.user_id,
    Customer: summary.display_name,
    Email: summary.email,
    Orders: summary.total_orders,
    Sales: `$${parseFloat(summary.total_sales ?? 0).toFixed(2)}`,
    Location: summary.last_order_location ?? '-',
    Venues: summary.total_places_involved_count,
    'Avg Order': `$${parseFloat(summary.average_sales ?? 0).toFixed(2)}`,
    '1st Order': summary.first_order
      ? `${format(new Date(summary.first_order), 'dd-MMM-yyyy, p')}`
      : '-',
    'Last Order': summary.last_order
      ? `${format(new Date(summary.last_order), 'dd-MMM-yyyy, p')}`
      : '-',
    'Pickup Orders': summary.pickup_orders,
    'Pickup Sales': `$${parseFloat(summary.pickup_sales ?? 0).toFixed(2)}`,
    'Dinein Orders': summary.dinein_orders,
    'Dinein Sales': `$${parseFloat(summary.dinein_sales ?? 0).toFixed(2)}`,
    'Delivery Orders': summary.delivery_orders,
    'Delivery Sales': `$${parseFloat(summary.delivery_sales ?? 0).toFixed(2)}`,
  }));

const searchUserOrdersDetailsQuery = gql`
  query searchUserOrdersDetailsQuery($input: SearchInput) {
    search_user_orders_details(input: $input) {
      user_order_details_listing {
        order_reference
        status
        display_name
        menu_order_id
        email
        app_type
        place_name
        date
        service_type
        amount
        payment_method
        contact {
          type
          is_primary
          value
        }
      }
      total_pages
      total_size
    }
  }
`;

// const filter = {
//   user_filter: { user_id: '6b3dd6e1-1f60-4971-b399-3fe4d81076db',
//   user_filter: {user_id: "6b3dd6e1-1f60-4971-b399-3fe4d81076db", display_name: "dsggsgsdg", email: "sgsgdsgsdg"

// },
//   menu_order_filter: {
//     order_reference: 'KAEANG1221',
//     status: 'CANCELLED',
//     payment_method: 'CARD',
//   },
//   service_type_setting_filter: { service_type: 'Delivery' },
//   place_filter: { place_name: 'Sister of Soul-stripes' },
// };

export const fetchUserOrdersDetails = async ({
  userId,
  adminUserId,
  from = 0,
  size = 50,
  placename,
  orderRef,
  paymentMethod,
  status,
  service,
  startDate,
  endDate,
  isTestPlace,
  isCashExclude,
}) => {
  try {
    const filterObj = {};

    filterObj.place_filter = {};
    filterObj.place_filter.is_cash_exclude = isCashExclude;
    filterObj.place_filter.is_test_place = isTestPlace;

    if (orderRef || status || paymentMethod || service) {
      filterObj.menu_order_filter = {};
    }
    if (orderRef) {
      filterObj.menu_order_filter.order_reference = orderRef;
    }

    if (status && status !== 'All') {
      filterObj.menu_order_filter.status = status;
    }

    if (paymentMethod && paymentMethod !== 'All') {
      filterObj.menu_order_filter.payment_method = paymentMethod;
    }

    if (service && service !== 'All') {
      filterObj.menu_order_filter.service_type = service;
    }

    if (placename) {
      filterObj.place_filter.place_name = placename;
    }

    if (new Date(startDate)?.getTime() === new Date(endDate)?.getTime()) {
      // pass
    } else if (startDate && endDate) {
      if (filterObj.menu_order_filter) {
        filterObj.menu_order_filter.date_range = {
          start_date: startDate,
          end_date: endDate,
        };
      } else {
        filterObj.menu_order_filter = {};
        filterObj.menu_order_filter.date_range = {
          start_date: startDate,
          end_date: endDate,
        };
      }
    }

    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: searchUserOrdersDetailsQuery,
      variables: {
        input: {
          filter: {
            user_filter: {
              user_id: [userId],
            },
            ...filterObj,
          },
          from,
          size,
          user_id: adminUserId,
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      data &&
      data.data &&
      data.data.search_user_orders_details &&
      Array.isArray(
        data.data.search_user_orders_details.user_order_details_listing,
      )
    ) {
      console.log(
        'search_transactions_summary',
        data.data.search_user_orders_details,
      );

      return {
        data: data.data.search_user_orders_details.user_order_details_listing,
        totalPages: data.data.search_user_orders_details.total_pages,
        totalSize: data.data.search_user_orders_details.total_size,
      };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const transformUserOrderDetails = details =>
  (details ?? []).map(detail => ({
    'Order Ref': detail.order_reference,
    menu_order_id: detail.menu_order_id,
    Status: detail.status,
    Customer: detail.display_name,
    Email: detail.email,
    Phone:
      (
        (detail?.contact ?? []).filter(
          ele => ele.type === 'mobile' && ele.is_primary,
        )[0] ?? {}
      ).value ?? '-',
    'Device Info': detail.app_type,
    'Place Name': detail.place_name,
    Date: format(new Date(detail.date), 'dd-MMM-yyyy, p'),
    Service: detail.service_type,
    Promo: '-',
    Amount: `$${parseFloat(detail?.amount ?? 0).toFixed(2)}`,
    Refund: '-',
    Method: detail.payment_method,
  }));

export const downloadCSVFile = (csvData, fileName) => {
  const CSVFile = new Blob([csvData], { type: 'text/csv' });
  const temp_link = document.createElement('a');
  temp_link.download = fileName;
  const url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;
  temp_link.style.display = 'none';
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
};

const searchOrdersQuery = gql`
  query searchOrders($input: SearchInput) {
    search_menu_orders(input: $input) {
      total_pages
      menu_order_listing {
        scheduled_date_time
        is_scheduled
        user_id
        menu_order_id
        estimated_completion_time
        no_of_attendees
        status
        table_number
        table_name
        pickup_method
        vehicle_information {
          car_detail
          license_plate
        }
        refunds {
          refund_id
          refund_amount
          refund_reason
          status
          refund_from_place
          refund_application_fee
          created_at
          created_by
          note {
            value
          }
        }
        payment {
          payment_method_type
          payment_method
          payment_method_id
          payment_intent_id
          payment_status
          payment_card {
            brand
            country
            last4
          }
          payment_at
        }
        menu {
          name
        }
        place {
          latitude
          longitude
          name
          city
        }
        large_order
        include_cuttlery
        address {
          type
          address_line_1
          address_line_2
          city
          state
          post_code
          country
          delivery_option {
            type
            caption
          }
        }
        place_id
        order_reference
        service_type
        received {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        confirmed {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        ready {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        complete {
          created_by
          created_at
          status
          user_type
          note {
            type
            value
            date
          }
        }
        cancelled {
          created_by
          created_at
          status
          user_type
          user_data {
            first_name
            last_name
            email
          }
          note {
            type
            value
            date
          }
        }

        overall_quantity
        line_item {
          name
          variant_name
          price
          overall_price
          modifier {
            name
            variant_name
            price
            price_per_item
            net_price_per_item
            quantity
          }
          special_request
          quantity
        }
        contact {
          contact_name
          contact_phone
        }
        billing {
          sub_total
          order_total
          deal_discount
          payment_gateway_fee
          adjustment_amount
          order_amount
          delivery_fee
          charge
          charges_list {
            name
            value
            is_payment_gateway_fee
          }
        }
        adjustments {
          name
          amount
          note
          adjusted_at
        }
        estimates {
          type
          value_in_mins
        }
        service_type_setting {
          display_table_number
          allow_pickup_options
          pickup_method
          pickup_instructions
          include_vehicle_information
        }
        audit {
          created_at
          created_by
        }
      }
    }
  }
`;

export const getOrders = input =>
  new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        query: searchOrdersQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data.search_menu_orders && data.search_menu_orders) {
          resolve(data.search_menu_orders);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export default {};
