import gql from 'graphql-tag';
import client from '../../utils/apolloClient';

const aggregateMenuOrderDrilldownQuery = gql`
  query aggregateMenuOrderDrilldownQuery(
    $userId: String
    $rangeType: String
    $isTestPlace: Boolean
    $isCashExclude: Boolean
  ) {
    aggregate_menu_order_drilldown(
      input: {
        user_id: $userId
        filter: {
          place_filter: {
            range_type: $rangeType
            is_cash_exclude: $isCashExclude
            is_test_place: $isTestPlace
          }
        }
      }
    ) {
      place_name
      sales_member_email
      service_types {
        service_type
        completed_orders
        sum_sale
        avg_sale
        payout
        delivery_fees
        srv_fee
        app_fee
        kravein_fee
        franchise_fee
        royalty_fee
        cancelled_orders
      }
      aggregate {
        avg_sale
        cancelled_orders
        completed_orders
        sum_sale
        payout
        delivery_fees
        srv_fee
        app_fee
        kravein_fee
        franchise_fee
        royalty_fee
      }
    }
  }
`;

const aggregateMenuOrderRollupQuery = gql`
  query aggregateMenuOrderRollupQuery(
    $userId: String
    $rangeType: String
    $isTestPlace: Boolean
    $isCashExclude: Boolean
  ) {
    aggregate_menu_order_rollup(
      input: {
        user_id: $userId
        filter: {
          place_filter: {
            range_type: $rangeType
            is_cash_exclude: $isCashExclude
            is_test_place: $isTestPlace
          }
        }
      }
    ) {
      aggregate {
        avg_sale
        cancelled_orders
        completed_orders
        sum_sale
        payout
        delivery_fees
        srv_fee
        app_fee
        kravein_fee
        franchise_fee
        royalty_fee
      }
      service_types {
        service_type
        completed_orders
        sum_sale
        avg_sale
        payout
        delivery_fees
        srv_fee
        app_fee
        kravein_fee
        franchise_fee
        royalty_fee
        cancelled_orders
      }
    }
  }
`;

const fetchAggregateMenuOrderDrilldown = async ({
  userId,
  rangeType,
  isCashExclude,
  isTestPlace,
}) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: aggregateMenuOrderDrilldownQuery,
      variables: { userId, rangeType, isCashExclude, isTestPlace },
      fetchPolicy: 'network-only',
    });
    if (data && data.data && data.data.aggregate_menu_order_drilldown) {
      return data.data.aggregate_menu_order_drilldown;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const fetchAggregateMenuOrderRollup = async ({
  userId,
  rangeType,
  isCashExclude,
  isTestPlace,
}) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: aggregateMenuOrderRollupQuery,
      variables: { userId, rangeType, isCashExclude, isTestPlace },
      fetchPolicy: 'network-only',
    });
    if (data && data.data && data.data.aggregate_menu_order_rollup) {
      return [data.data.aggregate_menu_order_rollup];
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const transformDrilldownData = drilldownData => {
  const temp = [];
  (drilldownData ?? []).forEach(ddData => {
    const { place_name: venue, aggregate, service_types, sales_member_email } =
      ddData ?? {};
    const allOrders = aggregate.completed_orders ?? '-';
    const allSales = `$${parseFloat(aggregate?.sum_sale || 0).toFixed(2)}`;
    const payout = `$${parseFloat(aggregate?.payout || 0).toFixed(2)}`;
    const missedOrders = aggregate.cancelled_orders ?? '-';
    const averageSales = `$${parseFloat(aggregate?.avg_sale || 0).toFixed(2)}`;
    const temp2 = {};
    (service_types ?? []).forEach(st => {
      temp2[st.service_type] = {
        sales: st.sum_sale,
        completed_orders: st.completed_orders,
      };
    });
    const pickupTotalOrders = temp2?.Pickup?.completed_orders ?? 0;
    const pickupSales = `$${parseFloat(temp2?.Pickup?.sales || 0).toFixed(2)}`;

    const dineinTotalOrders = temp2?.Dinein?.completed_orders ?? 0;
    const dineinSales = `$${parseFloat(temp2?.Dinein?.sales || 0).toFixed(2)}`;

    const deliveryTotalOrders = temp2?.Delivery?.completed_orders ?? 0;
    const deliverySales = `$${parseFloat(temp2?.Delivery?.sales || 0).toFixed(
      2,
    )}`;

    temp.push({
      venue,
      salesRep: sales_member_email,
      allOrders,
      allSales,
      payout,
      missedOrders,
      averageSales,
      pickupTotalOrders,
      pickupSales,
      dineinTotalOrders,
      dineinSales,
      deliveryTotalOrders,
      deliverySales,
      delFees: `$${parseFloat(aggregate?.delivery_fees || 0).toFixed(2)}`,
      kFees: `$${parseFloat(aggregate?.kravein_fee || 0).toFixed(2)}`,
      fFees: `$${parseFloat(aggregate?.franchise_fee || 0).toFixed(2)}`,
      bFees: `$${parseFloat(aggregate?.royalty_fee || 0).toFixed(2)}`,
      appfee: `$${parseFloat(aggregate?.app_fee || 0).toFixed(2)}`,
      srvfee: `$${parseFloat(aggregate?.srv_fee || 0).toFixed(2)}`,
    });
  });
  return temp;
};

const transformRollupData = rollupData => {
  const temp = [];
  (rollupData ?? []).forEach(rData => {
    const { aggregate, service_types } = rData;
    const allOrders = aggregate?.completed_orders ?? '-';
    const allSales = `$${parseFloat(aggregate?.sum_sale || 0).toFixed(2)}`;
    const payout = `$${parseFloat(aggregate?.payout || 0).toFixed(2)}`;
    const missedOrders = aggregate?.cancelled_orders ?? '-';
    const averageSales = `$${parseFloat(aggregate?.avg_sale || 0).toFixed(2)}`;
    const temp2 = {};
    (service_types ?? []).forEach(st => {
      temp2[st.service_type] = {
        sales: st.sum_sale,
        completed_orders: st.completed_orders,
      };
    });
    const pickupTotalOrders = temp2?.Pickup?.completed_orders ?? 0;
    const pickupSales = `$${parseFloat(temp2?.Pickup?.sales || 0).toFixed(2)}`;

    const dineinTotalOrders = temp2?.Dinein?.completed_orders ?? 0;
    const dineinSales = `$${parseFloat(temp2?.Dinein?.sales || 0).toFixed(2)}`;

    const deliveryTotalOrders = temp2?.Delivery?.completed_orders ?? 0;
    const deliverySales = `$${parseFloat(temp2?.Delivery?.sales || 0).toFixed(
      2,
    )}`;
    temp.push({
      allOrders,
      allSales,
      payout,
      missedOrders,
      averageSales,
      pickupTotalOrders,
      pickupSales,
      dineinTotalOrders,
      dineinSales,
      deliveryTotalOrders,
      deliverySales,
      delFees: `$${parseFloat(aggregate?.delivery_fees || 0).toFixed(2)}`,
      kFees: `$${parseFloat(aggregate?.kravein_fee || 0).toFixed(2)}`,
      fFees: `$${parseFloat(aggregate?.franchise_fee || 0).toFixed(2)}`,
      bFees: `$${parseFloat(aggregate?.royalty_fee || 0).toFixed(2)}`,
      appfee: `$${parseFloat(aggregate?.app_fee || 0).toFixed(2)}`,
      srvfee: `$${parseFloat(aggregate?.srv_fee || 0).toFixed(2)}`,
    });
  });
  return temp[0];
};

export {
  fetchAggregateMenuOrderDrilldown,
  fetchAggregateMenuOrderRollup,
  transformDrilldownData,
  transformRollupData,
};
